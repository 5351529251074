import React, { useState } from "react"
import logoPng from "../../assets/sequence-logo.png"
import iconPng from "../../assets/sequence-icon.png"
import openMenuPng from "../../assets/menu-open.png"
import closeMenuPng from "../../assets/menu-close.png"
import { NAV_LINKS, URIS } from "../../constants"
import { Button } from "../../components/Button"
import { MailingListInput } from "../../components/MailingList"

export const Header = ({ className = "" }) => {
  const [open, setOpen] = useState()
  const onClose = () => setOpen(false)

  return (
    <header
      className={`flex flex-1 justify-between mx-auto max-w-6xl mt-5 px-5 sm:mt-7 ${className}`}
    >
      <div className="flex items-center justify-center md:justify-start">
        <a className="hidden md:block" href="/">
          <img alt="logo" src={logoPng} style={{ height: 32 }} />
        </a>
      </div>

      <div className="hidden md:flex flex-1 justify-end items-center">
        {Object.entries(NAV_LINKS).map(([label, value]) => (
          <Link key={label} className="px-4" href={value}>
            {label}
          </Link>
        ))}

        <Button
          variant="outlined"
          className="ml-5"
          style={{ background: "black" }}
          href={URIS.wallet}
          target="_blank"
        >
          Launch Wallet
        </Button>
      </div>

      <div className="md:hidden">
        <MobileNav open={open} setOpen={setOpen} onClose={onClose} />
      </div>
    </header>
  )
}

const MobileNav = ({ setOpen, open, onClose }) => (
  <div>
    <button onClick={() => setOpen(true)}>
      <img alt="menu-open" src={openMenuPng} style={{ height: 55 }} />
    </button>

    <div
      className="fixed inset-0 pointer-events-none z-40"
      style={{ opacity: open ? 0.5 : 0, background: "black" }}
    />

    <nav
      className={`py-5 pl-5 pr-5 fixed z-50 opacity-${
        open ? "100" : "0"
      } pointer-events-${open ? "auto" : "none"}`}
    >
      <div className="flex items-start justify-between">
        <a href="https://sequence.build" rel="noreferrer" target="_blank">
          <img alt="logo" src={iconPng} style={{ height: 55 }} />
        </a>

        <button onClick={onClose}>
          <img alt="menu-close" src={closeMenuPng} style={{ height: 55 }} />
        </button>
      </div>

      <div className="my-8">
        {Object.entries(NAV_LINKS).map(([label, value]) => (
          <Link key={label} className="block mr-4" href={value} onClick={onClose}>
            {label}
          </Link>
        ))}
      </div>

      <div className="mb-8 inline-block">
        <Button
          variant="outlined"
          href={URIS.wallet}
          target="_blank"
        >
          Launch Wallet
        </Button>
      </div>

      <div style={{ maxWidth: 380, display: open ? "auto" : "none" }}>
        <MailingListInput grayscale small />
      </div>

      <p></p>

      <div className="mb-2" />

      <p>Copyright &copy; {new Date().getFullYear()} Horizon Blockchain Games Inc.</p>

      <div className="mb-5" />
    </nav>
  </div>
)

const Link = ({ children, href = "", sup = "", disabled = false, ...props }) => (
  <a href={href} target={href.match(/^http/) && !disabled ? "_blank" : undefined} rel="noreferrer" {...props} disabled={disabled}>
    {children}<sup>{sup}</sup>
  </a>
)
